<template>
  <div class="login1">
    <div class="hd">
      <div class="logo">
        <div class="img" v-if="official_display"><img src="~@/assets/logo.png" /></div>
        <div class="text">
          <h1 v-if="official_display">Ai团餐</h1>
          <b v-if="official_display">|</b>
          <p>{{logoText}}</p>
        </div>
      </div>
      <div class="tel">
        咨询热线：<b class="fnum">0769-22383199</b>
      </div>
    </div>
    <div class="bd">
      <div class="fifo">
        <div class="figure">
          <img src="~@/assets/images/login-figure.png" />
        </div>
        <div class="tifo">
          <div class="con">
            <div class="title">
              <h1>账户登录</h1>
              <p>还没有账户，请与客服联系</p>
            </div>
            <div class="form">
              <a-form
                :form="form"
                @submit="handleSubmit"
              >
                <a-form-item>
                  <a-input
                    size="large"
                    v-decorator="['name',{rules: [{ required: true, message: '请输入用户名！' }]}]"
                    placeholder="用户名"
                  >
                    <icon-font
                      slot="prefix"
                      type="icon-yonghu"
                      class="in-icon"
                      style="color: rgba(0,0,0,.25)"
                    />
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input
                    size="large"
                    v-decorator="['pwd',{rules: [{ required: true, message: '请输入密码！' }]}]"
                    type="password"
                    placeholder="密码"
                  >
                    <icon-font
                      slot="prefix"
                      type="icon-mima"
                      class="in-icon"
                      style="color: rgba(0,0,0,.25)"
                    />
                  </a-input>
                </a-form-item>
                <div class="button">
                  <a-button
                    block
                    type="primary"
                    size="large"
                    html-type="submit"
                  >
                    登录
                  </a-button>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IconFont from '@/components/Iconfont'
import footerComponent from '@/components/Footer'
import apis from '@/business/apis'
export default {
  components: {
    IconFont,
    footerComponent
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'normal_login' }),
      logoText: '智慧食堂，就用Ai团餐',
      official_display: true
    }
  },
  computed: {
    ...mapGetters('user', [
      'isTeacher'
    ])
  },
  methods: {
    ...mapActions('school', [
      'setCurrSchool',
      'setCurrOrg'
    ]),
    ...mapActions('user', [
      'saveCurrUser'
    ]),
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.$route.params.orgCode) {
            this.orglogin(values)
          } else if (this.$route.params.schoolCode) {
            this.schoolLogin(values)
          } else {
            this.$message.info('url地址错误')
          }
        }
      })
    },
    async orglogin (info) {
      info.orgCode = this.$route.params.orgCode
      const response = await this.$http.post(apis.orgAdmin.login, info)
      if (!response.body.success) return

      response.body.organization.official_display = this.official_display
      await this.setCurrOrg(response.body.organization)
      response.body.user.isOrgAdmin = true
      await this.saveCurrUser(response.body.user)

      this.$router.push({
        name: 'SelectSchool'
      })
    },
    async schoolLogin (info) {
      info.schoolCode = this.$route.params.schoolCode
      const response = await this.$http.post(apis.schoolAdmin.login, info)
      if (!response.body.success) return

      response.body.school.official_display = this.official_display
      await this.setCurrSchool(response.body.school)
      response.body.user.isOrgAdmin = false
      await this.saveCurrUser(response.body.user)

      let result = await this.$http.get(apis.studentGroup.count + response.body.school._id + '/count')
      if (!result.body.count) {
        this.$router.push({ name: 'ClassConfig' })
        return
      }
      if (this.isTeacher) {
        this.$router.push({ name: 'NutritionMonitorIndex' })
        return
      }
      result = await this.$http.get(apis.user.count + '?schoolId=' + response.body.school._id)
      if (!result.body.count) {
        this.$router.push({ name: 'FileManage' })
        return
      }
      this.$router.push({ name: 'NutritionMonitorIndex' })
    }
  },
  async mounted () {
    let url = ''
    if (this.$route.params.orgCode) {
      url = apis.organization.orgName + this.$route.params.orgCode
    } else if (this.$route.params.schoolCode) {
      url = apis.school.schoolName + this.$route.params.schoolCode
    }
    if (url) {
      const response = await this.$http.get(url)
      if (response && response.body) {
        this.logoText = response.body.name || this.logoText
        this.official_display = response.body.official_display
      }
    }
    await this.setCurrOrg({ official_display: this.official_display })
  }
}
</script>
<style lang="less">
.login1 {
  > .hd {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    > .logo {
      flex: 1;
      display: flex;
      align-items: center;
      .img {
        width: 32px;
        height: 32px;
        img {
          width: 100%;
        }
      }
      .text {
        margin-left: 4px;
        line-height: 1;
        display: flex;
        align-items: center;
        h1 {
          font-size: 22px;
          font-weight: bold;
          margin: 0;
        }
        b {
          font-weight: normal;
          margin: 0 8px;
        }
        p {
          margin: 0;
        }
      }
    }
    .tel {
      display: flex;
      align-items: center;
      .fnum {
        font-size: 20px;
      }
    }
  }
  > .bd {
    padding: 100px 0 100px;
    background-color: #f2f2f2;
    background: url(../assets/images/bg.jpg);
    background-size: cover;
    background-position: center;
    .fifo {
      width: 980px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .figure {
        flex: 1;
        margin-right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
        }
      }
      .tifo {
        width: 360px;
        height: 400px;
        background-color: #fff;
        box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .con {
          width: 280px;
          .title {
            margin-top: -4px;
            margin-bottom: 24px;
            h1 {
              font-weight: bold;
              font-size: 24px;
              margin: 0;
            }
            p {
              margin: 2px 0 0;
              font-size: 14px;
              color: #9a9a9a;
            }
          }
          .form {
            .ant-input-affix-wrapper {
              .ant-input-prefix {
                left: 11px;
              }
              .ant-input {
                height: 48px;
              }
            }
            .ant-form-item {
              margin-bottom: 32px;
            }
            .ant-form-explain {
              position: absolute;
              top: 55px;
            }
            .button {
              padding-top: 4px;
              .ant-btn {
                height: 50px;
                font-size: 16px;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
